import Analytics, { AnalyticsInstance } from 'analytics'
import googleAnalytics from '@analytics/google-analytics'
import googleTagManager from '@analytics/google-tag-manager'
import { facebookPixel } from './facebookPixel'
import { metaCapi } from './integrationChannels/metaCapi'
import { Channel } from './integrationChannels/integrationChannels.model'
import { UAParser } from 'ua-parser-js'
import type { AnalyticsServiceSettingsDto } from 'models/studio'
import type { SuperProperties } from './analytics.model'
import { heap } from './heap'
import { tealium } from './tealium'
import { flattenObjectForGA4 } from './dataLayerConverters'

// Define a variable to hold the analytics instance
let analytics: AnalyticsInstance | null = null

// Define an interface for events that will be queued when analytics is not initialized
interface QueuedEvent {
  method: 'track' | 'page' | 'once'
  args: unknown[]
  eventName?: string
  callback?: (...params: unknown[]) => unknown
}

// Initialize an array to hold the queued events
const eventQueue: Array<QueuedEvent> = []

// Process and send events that were queued when analytics was not initialized
const processEventsQueue = () => {
  if (analytics) {
    while (eventQueue.length > 0) {
      const { method, args, eventName, callback } = eventQueue.shift()!

      if (method === 'once' && eventName && callback) {
        analytics.once(eventName, callback)
      } else {
        // @ts-expect-error | Typescript doesn't like spreading args into a function
        analytics[method](...args)
      }
    }
  }
}

// Function to initialize the analytics instance
export const initializeAnalytics = (
  settings: AnalyticsServiceSettingsDto[],
  superProperties: SuperProperties,
) => {
  if (analytics !== null) {
    console.warn('Analytics instance has already been initialized.')
    return
  }

  // Detect bots and avoid initializing analytics for them
  const bots = [
    [/(GoogleBot)\/([\w\.]+)/i],
    [UAParser.BROWSER.NAME, UAParser.BROWSER.VERSION, ['type', 'bot']],
  ]

  const uaParser = new UAParser({ browser: bots })

  // @ts-expect-error | TODO: Validate if this is proper way to check for bot
  if (uaParser.getBrowser()?.type === 'bot') {
    console.warn('Analytics not initialized for bot traffic')
    return
  }

  const plugins = []

  // Add Google Analytics 4 if settings are provided
  const ga4Settings = settings.find((setting) => setting.service_name === 'google_analytics_4') as
    | AnalyticsServiceSettingsDto<{ measurement_id: string }, object>
    | undefined

  if (ga4Settings?.parameters?.measurement_id) {
    const originalGa4 = googleAnalytics({ measurementIds: [ga4Settings.parameters.measurement_id] })

    const customGa4 = Object.assign({}, originalGa4, {
      /* @ts-expect-error -- ignore "any" type warning, analytics plugin does not provide types */
      track: ({ payload: originalPayload, ...rest }) => {
        const payload = {
          ...originalPayload,
          properties: flattenObjectForGA4(originalPayload.properties),
        }

        originalGa4.track({ payload, ...rest })
      },
    })

    plugins.push(customGa4)
  }

  // Add Google Tag Manager if settings are provided
  const gtmSettings = settings.find((setting) => setting.service_name === 'google_tag_manager') as
    | AnalyticsServiceSettingsDto<{ container_id: string }, object>
    | undefined

  if (gtmSettings?.parameters?.container_id) {
    plugins.push(googleTagManager({ containerId: gtmSettings.parameters.container_id }))
  }

  /**
   * Check for FacebookPixel
   */
  const facebookPixelSettings = settings.find(
    (setting) => setting.service_name === 'facebook_pixel',
  ) as
    | AnalyticsServiceSettingsDto<{ pixel_id: string; meta_access_token: string }, object>
    | undefined

  if (facebookPixelSettings && facebookPixelSettings?.parameters?.pixel_id) {
    plugins.push(
      facebookPixel({
        pixelId: facebookPixelSettings.parameters?.pixel_id,
      }),
    )
  }

  /**
   * Check for Meta CAPI
   */
  const metaCapiSettings = settings.find((setting) => setting.service_name === 'MetaCapi') as
    | AnalyticsServiceSettingsDto<{ pixel_id: string; meta_access_token: string }, object>
    | undefined

  if (metaCapiSettings?.status === 'enabled') {
    plugins.push(
      metaCapi({
        orgId: superProperties.orgId,
      }),
    )
  }

  /**
   * Check for Tealium Settings
   */
  const tealiumSettings = settings.find((setting) => setting.service_name === 'tealium') as
    | AnalyticsServiceSettingsDto<{ account: string; profile: string }, object>
    | undefined

  if (tealiumSettings) {
    const { account, profile } = tealiumSettings?.parameters
    plugins.push(tealium({ account, profile, ...superProperties }))
  }

  // Always add Heap
  plugins.push(heap(superProperties))

  // Initialize the analytics instance
  analytics = Analytics({
    app: 'Whammy',
    plugins,
  })

  // Process the queued events
  processEventsQueue()
}

/**
 * Can be used to send events to upstream analytics providers.
 *
 * Example:
 *
 * const analytics = getAnalyticsInstance();
 *
 * analytics.track('cartCheckout', {
 *   item: 'pink socks',
 *   price: 20
 * })
 *
 * @returns AnalyticsInstance
 */
// @ts-expect-error | The returned shim is not a complete implementation of the AnalyticsInstance
export const getAnalyticsInstance: () => AnalyticsInstance = () => {
  if (!analytics) {
    console.warn('Analytics instance has not been initialized. Queuing up Analytics event.')

    // Shim any necessary calls here
    return {
      track: (...args: unknown[]) => eventQueue.push({ method: 'track', args }),
      page: (...args: unknown[]) => eventQueue.push({ method: 'page', args }),
      once: (eventName: string, callback?: (...params: unknown[]) => unknown) => {
        eventQueue.push({
          method: 'once',
          args: [],
          eventName,
          callback,
        })

        return () => {}
      },
      storage: () => {},
    }
  }

  return analytics
}

export const resolveAnalyticsSettings = (
  analyticsSettings: AnalyticsServiceSettingsDto[],
  channelsSettings: Channel[],
  orgId: string,
) => {
  const orgSettings = analyticsSettings.filter(
    (setting) => setting.trackable_type === 'organization',
  )
  const campaignSettings = analyticsSettings.filter(
    (setting) => setting.trackable_type === 'campaign',
  )

  // * Transform the channels to the analytics settings format
  const transformedChannelsSettings = channelsSettings?.map((channel) => ({
    id: channel.id,
    service_name: channel.channel_name,
    parameters: channel.additional_configurations,
    trackable_id: Number(orgId),
    trackable_type: 'organization',
    tracked_events: [],
    status: channel.status,
  }))

  const resolvedAnalyticsSettings = [
    ...orgSettings.filter(
      (orgSetting) =>
        !campaignSettings.some(
          (campaignSetting) => campaignSetting.service_name === orgSetting.service_name,
        ),
    ),
    ...campaignSettings,
    ...(transformedChannelsSettings as AnalyticsServiceSettingsDto[]),
  ]

  return resolvedAnalyticsSettings
}
