import { CLASSY_LOGIN_DEFAULT_SCOPES } from 'models/sso'
import { isClient, isLocalEnv, urlProtocol } from 'utils/environment'

/**
 * Methods for interacting with our SSO service.
 */
export const onLogin = async (orgId?: string) => {
  const loginResponse = await fetch(
    `/api/auth/login?originalUrl=${encodeURIComponent(window.location.href)}`,
  )
  const state = await loginResponse.json()

  const redirectUri = `${urlProtocol}://${process.env.CLASSY_STUDIO_DOMAIN}/api/auth/callback`

  const ssoLoginParams = {
    client_id: process.env.KONG_CLIENT_ID ?? '',
    response_type: 'code',
    state,
    redirect_uri: redirectUri,
    okta_client_id: process.env.OKTA_CLIENT_ID ?? '',
    okta_scope: CLASSY_LOGIN_DEFAULT_SCOPES.replace(/,/g, ' '),
    mode: 'json',
    ...(orgId ? {} : { org_id: orgId }),
  }

  // Redirect to SSO on the same domain that the user is on so that cookies are set
  // on the proper domain
  const ssoBaseUrl =
    window.location.host !== process.env.CLASSY_STUDIO_DOMAIN && !isLocalEnv
      ? window.location.origin
      : process.env.CLASSY_BASE_URI

  const ssoPath = '/sso'
  const params = new URLSearchParams(ssoLoginParams)

  const ssoUrl = `${ssoBaseUrl}${ssoPath}?${params}`
  redirect(ssoUrl)
}

/**
 * Client-side only redirect
 */
export const redirect = (url: string) => {
  if (isClient) {
    window.location.href = url
  }
}
