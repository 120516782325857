import { APIv2Fetch } from 'classy-api-proxies/apiv2'
import {
  ChannelPageResponse,
  ChannelEventResponse,
  CreateChannelEventPayload,
} from './integrationChannels.model'

/**
 * Get a list of third-party services integrated with an Organization (ie Meta CAPI).
 *
 * @see https://dev-docs.classy-test.org/apiv2/#tag/Organization-Channel/operation/listOrganizationChannel
 *
 */
export async function getOrganizationChannels(
  organization_id: string,
): Promise<ChannelPageResponse> {
  const response = await APIv2Fetch(`/organizations/${organization_id}/channels`, {
    method: 'GET',
  })

  const channels: ChannelPageResponse = await response.json()

  return channels
}

/**
 * Post an event (ie. analytics.page, analytics.track) to a third-party service
 * integrated with the Organization (ie Meta CAPI)
 *
 * createOrganizationChannelEvent
 * @see https://dev-docs.classy-test.org/apiv2/#tag/ChannelEvent/operation/createOrganizationChannelEvent
 *
 */
export async function createChannelEvent(
  organization_id: string,
  body: CreateChannelEventPayload,
): Promise<ChannelEventResponse> {
  const response = await fetch(`/api/organizations/${organization_id}/channel-events`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })

  const json: ChannelEventResponse = await response.json()

  return json
}
