import { PHASE_PRODUCTION_BUILD } from 'next/dist/shared/lib/constants'

/**
 * Helper functions and constants related to environments.
 *
 * NODE_ENV Notes:
 * - For `yarn dev`, NODE_ENV is "development".
 * - For `yarn build`, NODE_ENV is "production".
 *   - Important! When deploying to dev, staging, and prod, a `yarn build` is always run, which
 *     means NODE_ENV is always "production" regardless. This is why we have APP_ENV!
 * - For `yarn jest`, NODE_ENV is "test".
 *
 * APP_ENV Notes:
 * - Set by the currently loaded .env (or .env.local) file.
 * - The environment names "dev", "staging", and "prod" are deliberately not the same names as
 *   NODE_ENV to match other repos at Classy.
 *
 * .env File Notes:
 * - Contrary to the NextJS docs, we do not load the .env file based on NODE_ENV.
 *   - See bitbucket-pipelines.yml and scripts/setup_build_env.sh.
 * - See https://nextjs.org/docs/pages/building-your-application/configuring/environment-variables#environment-variable-load-order
 *     for how NextJS looks up .env files.
 *
 * NEXT_PHASE Notes:
 * - Is set to "phase-production-build" during build time (yarn build), otherwise undefined.
 * - Can be used to differentiate between build time (yarn build) and runtime (docker).
 * - Warning! This constant is not officially documented by NextJs and its use should be minimal.
 */

/**
 * Indicates Whammy is:
 * - Running in a (NODE_ENV) production environment.
 * - Being built (yarn build).
 *
 * Primarily used to NOT load NewRelic when the app is being built. See _document.tsx.
 */
export const isProdBuildtime =
  process.env.NODE_ENV === 'production' && process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD

/**
 * Indicates Whammy is running in development mode (yarn dev).
 *
 * Primarily used to prevent actions during local development, like sending logs to BugSnag.
 */
export const isDevMode = process.env.IS_DEV_MODE

/**
 * Helper functions for when the environment matters.
 */
export const isLocalEnv = process.env.APP_ENV === 'local'
export const isDevEnv = process.env.APP_ENV === 'dev'
export const isTestEnv = process.env.NODE_ENV === 'test'
export const isLocalOrTestEnv = isLocalEnv || isTestEnv
export const isProdEnv = process.env.APP_ENV === 'prod'

export const urlProtocol = !isLocalEnv ? 'https' : 'http'

/**
 * Server-side rendering (SSR) helpers
 */
export const isServer = process.env.IS_SERVER_CODE
export const isClient = !process.env.IS_SERVER_CODE
export const hasLocalStorage = () => Boolean(isClient && window.localStorage)
