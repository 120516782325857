import React from 'react'
import styled from '@emotion/styled'
import Script from 'next/script'
import { CLASSY_LOGIN_DEFAULT_SCOPES } from 'models/sso'
import { isLocalEnv, urlProtocol } from 'utils/environment'
import { getCookieValue } from 'utils/getCookieValue'
import { PageConfig } from 'models/pages'

interface HiddenSsoIframeProps {
  flow: string
  org_id?: string
  campaign_id?: string
}

const HiddenSsoIframe = styled.div<HiddenSsoIframeProps>`
  width: 1px;
  height: 1px;
  opacity: 0;
  overflow: hidden;
  display: none;
`

interface SsoSdkProps {
  onReady: () => void
  pageConfig?: PageConfig
}

export const SsoSdk = ({ onReady, pageConfig }: SsoSdkProps) => {
  const [ucl, setUcl] = React.useState<boolean | undefined>(undefined)
  React.useEffect(() => {
    const uclCookieValue = getCookieValue('ucl') === 'true' ? true : false

    setUcl(uclCookieValue)
  }, [])

  const baseUrl =
    typeof window !== 'undefined' &&
    window.location.host !== process.env.CLASSY_STUDIO_DOMAIN &&
    !isLocalEnv
      ? window.location.origin
      : process.env.CLASSY_BASE_URI

  const ssoParams = {
    client_id: process.env.KONG_CLIENT_ID,
    okta_client_id: process.env.OKTA_CLIENT_ID,
    app_cookie_name: 'whammy',
    scope: CLASSY_LOGIN_DEFAULT_SCOPES.replace(/,/g, ' '),
    okta_scope: CLASSY_LOGIN_DEFAULT_SCOPES.replace(/,/g, ' '),
    redirect_uri: `${urlProtocol}://${process.env.CLASSY_STUDIO_DOMAIN}/api/auth/callback`,
    response_type: 'code',
    override_base_url: baseUrl,
    skip_iframe_resizer: true,
    skip_dom_parsing: true,
    mode: 'json',
  }

  const ssoSource = ucl ? `${baseUrl}/auth/sdk.js` : `${baseUrl}/sso/sdk.js`

  return ucl === undefined ? null : (
    <>
      <Script
        id="classy-sso-sdk"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(s,d,i,w,p,n) {
      w['ClassyObjectName'] = n;
      w[n]=w[n]||(function(){var o={};o.params=p;o.ready=function(c){(this.onReady=this.onReady||[]).push(c)};return o})()
      var b, a = d.getElementsByTagName(s)[0];
      b = d.createElement(s);b.id=i;b.async=true;
      b.src = "${ssoSource}";
      a.parentNode.insertBefore(b, a);
    })('script',document,'classy-js-sdk',window,${JSON.stringify(ssoParams)}, 'Classy');`,
        }}
        onReady={onReady}
      />
      {/* We include a hidden iframe so we can interact with SSO through the SDK. Additional context here:
      https://classy.atlassian.net/wiki/spaces/API/pages/559415330/SDK+Solution#Side-Effects-of-Importing-Script */}
      <HiddenSsoIframe
        className="classy-login-iframe"
        flow="login_only"
        org_id={pageConfig?.orgId}
        campaign_id={pageConfig?.campaignId}
      />
    </>
  )
}
