import type { AnalyticsInstance } from 'analytics'
import type { AnalyticsPayload, SuperProperties } from './analytics.model'

export type HeapCookie = {
  userId: string
  pageviewId: string
  sessionId: string
}

type PluginData = {
  config: SuperProperties
  payload: AnalyticsPayload
  instance: AnalyticsInstance
}

/**
 * Custom heap analytics plugin:
 * https://getanalytics.io/plugins/writing-plugins/
 * https://developers.heap.io/docs/web
 */
export const heap = (heapSuperProperties: SuperProperties) => ({
  name: 'heap',
  config: {
    ...heapSuperProperties,
    campaignType: 'studio',
  },
  initialize(): void {
    // * Init Heap Script
    const heapScript = document.createElement('script')
    heapScript.type = 'text/javascript'
    heapScript.id = 'heap-analytics'
    heapScript.async = true
    heapScript.text = `
      window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};heap.load('${process.env.HEAP_APP_ID}');
    `
    // * Inject Heap Script into the DOM
    document.body.prepend(heapScript)
  },
  page({ payload, config: superProperties }: PluginData): void {
    window.heap.track('Page View', { ...superProperties, ...payload.properties })
  },
  track({ payload, config: superProperties }: PluginData): void {
    const { event, properties } = payload
    if (event) {
      window.heap.track(event, { ...superProperties, ...properties })
    }
  },
  loaded(): boolean {
    return !!window.heap
  },
})
