import Bugsnag from '@bugsnag/js'
import { pinoLogger } from './pino'
import { isDevMode, isServer } from './environment'

/**
 * Start Bugsnag
 */
export const start = async () => {
  if (isDevMode || !process.env.BUGSNAG_API_KEY) {
    return
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const plugins: any[] = []

  /**
   * We only want to include the @bugsnag/plugin-aws-lambda plugin if we are on the server.
   * This is because this plugin only works when we are using AWS Lambda.
   *
   * ! FYI, not sure if we currently use this. It might be a Vercel-only thing.
   */
  if (isServer) {
    plugins.push(await import('@bugsnag/plugin-aws-lambda'))
  }

  Bugsnag.start({
    apiKey: process.env.BUGSNAG_API_KEY,
    appVersion: `${process.env.APP_ENV}-${process.env.NEXT_BUILD_ID}`,
    plugins,
    releaseStage: process.env.APP_ENV,
    /**
     * Note, logger is only used when the log is sent to the console instead of BugSnag.
     * None of Pino's configuration options change/affect what BugSnag does.
     */
    logger: pinoLogger,
  })
}

/**
 * Returns the Bugsnag plugin for use with AWS Lambda functions.
 *
 * ! FYI, not sure if we currently use this. It might be a Vercel-only thing.
 */
export const getServerlessHandler = () => Bugsnag.getPlugin('awsLambda')?.createHandler()
