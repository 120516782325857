import {
  Domine,
  Inter,
  Karla,
  Lato,
  Merriweather,
  Montserrat,
  Open_Sans,
  Oswald,
  Poppins,
  PT_Sans,
  Raleway,
  Roboto,
  Slabo_13px,
  Slabo_27px,
  Source_Sans_3,
} from 'next/font/google'

// * Supported Google Font Setup
export const domine = Domine({
  subsets: ['latin'],
  weight: ['400', '500', '700'],
  style: ['normal'],
})
export const inter = Inter({
  subsets: ['latin'],
  weight: ['300', '400', '500', '700'],
  style: ['normal'],
})
export const karla = Karla({
  subsets: ['latin'],
  weight: ['300', '400', '500', '700'],
  style: ['normal', 'italic'],
})
export const lato = Lato({
  subsets: ['latin'],
  weight: ['300', '400', '700'],
  style: ['normal', 'italic'],
})
export const merriweather = Merriweather({
  subsets: ['latin'],
  weight: ['300', '400', '700'],
  style: ['normal', 'italic'],
})
export const montserrat = Montserrat({
  subsets: ['latin'],
  weight: ['300', '400', '500', '700'],
  style: ['normal', 'italic'],
})
export const openSans = Open_Sans({
  subsets: ['latin'],
  weight: ['300', '400', '500', '700'],
  style: ['normal', 'italic'],
})
export const oswald = Oswald({
  subsets: ['latin'],
  weight: ['300', '400', '500', '700'],
  style: ['normal'],
})
export const poppins = Poppins({
  subsets: ['latin'],
  weight: ['300', '400', '500', '700'],
  style: ['normal', 'italic'],
})
export const ptSans = PT_Sans({
  subsets: ['latin'],
  weight: ['400', '700'],
  style: ['normal', 'italic'],
})
export const raleway = Raleway({
  subsets: ['latin'],
  weight: ['300', '400', '500', '700'],
  style: ['normal', 'italic'],
})
export const roboto = Roboto({
  subsets: ['latin'],
  weight: ['300', '400', '500', '700'],
  style: ['normal', 'italic'],
})
export const slabo13px = Slabo_13px({
  subsets: ['latin'],
  weight: '400',
  style: 'normal',
})
export const slabo27px = Slabo_27px({
  subsets: ['latin'],
  weight: '400',
  style: 'normal',
})
export const sourceSans3 = Source_Sans_3({
  subsets: ['latin'],
  weight: ['300', '400', '500', '700'],
  style: ['normal', 'italic'],
})

// * Emotion mix-in which sets supported Google fonts as CSS Custom Properties
export const CLASSY_GOOGLE_FONTS = {
  '--classy-font__font-family--domine': domine.style.fontFamily,
  '--classy-font__font-family--inter': inter.style.fontFamily,
  '--classy-font__font-family--karla': karla.style.fontFamily,
  '--classy-font__font-family--lato': lato.style.fontFamily,
  '--classy-font__font-family--merriweather': merriweather.style.fontFamily,
  '--classy-font__font-family--montserrat': montserrat.style.fontFamily,
  '--classy-font__font-family--open-sans': openSans.style.fontFamily,
  '--classy-font__font-family--oswald': oswald.style.fontFamily,
  '--classy-font__font-family--poppins': poppins.style.fontFamily,
  '--classy-font__font-family--pt-sans': ptSans.style.fontFamily,
  '--classy-font__font-family--raleway': raleway.style.fontFamily,
  '--classy-font__font-family--roboto': roboto.style.fontFamily,
  '--classy-font__font-family--slabo-13px': slabo13px.style.fontFamily,
  '--classy-font__font-family--slabo-27px': slabo27px.style.fontFamily,
  '--classy-font__font-family--source-sans-3': sourceSans3.style.fontFamily,
}
