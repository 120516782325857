import Bugsnag, { Event } from '@bugsnag/js'
import { Level as PinoLevel } from 'pino'
import { isClient, isProdEnv } from './environment'
import { pinoLogger } from './pino'

interface BugsnagNotifyOptions {
  context?: Record<string, unknown>
  onError?: (event: Event) => void
}

const allowedBugSnagErrorLevels = ['error', 'fatal']

/**
 * Send a log to BugSnag and stdout.
 *
 * Note, stdout refers to the browser console, terminal, and CloudWatch.
 *
 * Where logs are sent depends on the type of build:
 * - Production builds
 *   - Server and client logs are sent to BugSnag.
 *   - Server logs are also sent to stdout (CloudWatch).
 * - Dev builds
 *   - All logs are sent to stdout (console, terminal).
 *
 * For logs sent to stdout, Pino is used to generate a standardized
 * JSON-formatted object. Note, Pino does not affect/change BugSnag.
 *
 * ----
 * Level/Severity
 *
 * Available levels come from Pino:
 * trace, debug, info, warn, error, fatal
 *
 * BugSnag only supports info, warning, and error. This function will narrow
 * the Pino-level for BugSnag.
 *
 * ! Due to rate-limiting concerns with BugSnag, we only send "error" and "fatal".
 *
 * ! On (APP_ENV) production, all client-side logs below "error" will be ignored
 * ! to avoid flooding the user's browser console.
 *
 * Pino has a "level" option, which sets the minimum level to log. It ONLY affects
 * what is sent to stdout.
 *
 * ----
 * Context/Metadata
 *
 * To make logs more useful, an object of relevant data can be provided via the
 * "options.context" property.
 *
 * For BugSnag, "options.context" is attached as metadata under the name "Log Metadata".
 *
 * For stdout, "options.context" is merged into the JSON log object.
 * Example: { message: 'Ooops', ..., context: { ...options.context } }
 *
 * ----
 * Examples
 *
 * logger('info', 'The thing is starting', { context: { foo: 'baz' } })
 * logger('error', new Error('Ooops'), { context: { foo: 'baz' } })
 *
 * Catching a thrown error:
 * (Note the usage of "cause". Both BugSnag and Pino will recognize it.)
 *
 * catch (e) {
 *   logger(
 *     'error',
 *     new Error('Caught error', { cause: e }),
 *     { context: { foo: 'baz' } }
 *   )
 * }
 *
 * Customize a BugSnag report:
 * (See https://docs.bugsnag.com/platforms/javascript/reporting-handled-errors/#customizing-diagnostic-data
 * for available options.)
 *
 * logger('error', new Error('oops'), {
 *   onError: (event) => {
 *     ...
 *   }
 * })
 */
export const logger = (
  level: PinoLevel,
  error: Error | string,
  options: BugsnagNotifyOptions = {},
) => {
  if (Bugsnag.isStarted() && allowedBugSnagErrorLevels.includes(level)) {
    Bugsnag.notify(error, (event) => {
      if (['trace', 'debug', 'info'].includes(level)) {
        event.severity = 'info'
      } else if (level === 'warn') {
        event.severity = 'warning'
      } else {
        event.severity = 'error'
      }

      if (options.context) {
        event.addMetadata('log-metadata', options.context)
      }

      if (options.onError) {
        // Per BugSnag, returning false discards the event
        return options.onError(event)
      }
    })
  }

  /**
   * On the (APP_ENV) production environment, ignore all client-side logs to avoid
   * flooding the user's browser console.
   */
  if (isProdEnv && isClient) {
    return
  }

  /**
   * Send all logs to stdout.
   */
  pinoLogger[level]({
    context: { ...options.context },
    // Note, we override Pino's "msg" key name to "message"
    ...(typeof error === 'string' && { message: error }),
    // Note, we override Pino's "err" key name to "error"
    ...(error instanceof Error && { error }),
  })
}
