import pino from 'pino'
import { isProdEnv, isTestEnv } from './environment'

const timestamp = (): string => {
  const time = Date.now()

  return `,"time":${time},"timestamp":"${new Date(time).toString()}"`
}

const formatLevel = (label: string, number: number) => ({
  level: number,
  'log.level': label,
})

/**
 * Determine log level
 *
 * Assume prod (e.g. "info" and higher), then adjust for lower environments.
 */
let level = 'info'
if (isTestEnv) {
  // Don't flood Jest with trace -> warn
  level = 'error'
} else if (!isProdEnv) {
  level = 'trace'
}

/**
 * Format log messages (using Pino) and sends to process.stdout.
 *
 * Note, in most cases, please use the `logger()` function.
 *
 * pinoLogger.trace()
 * pinoLogger.debug()
 * pinoLogger.info()
 * pinoLogger.warn()
 * pinoLogger.error()
 * pinoLogger.fatal()
 *
 * Each logger function takes up to three parameters:
 * (https://getpino.io/#/docs/api?id=trace)
 * - ({ mergingObject }, "message", ...interpolationValues)
 * - ({ error: Error }, "message", ...interpolationValues)
 * - ({ message: "message" }, ...interpolationValues)
 * - (Error, "message", ...interpolationValues)
 * - ("message", ...interpolationValues)
 *
 * Examples:
 * pinoLogger.error(new Error('Blah blah'))
 * pinoLogger.error({ campaignId: 1 }, 'Blah blah')
 * pinoLogger.error({ message: 'Blah blach', campaignId: 1 })
 * pinoLogger.error('Hello %s %s', 'silly' 'world') -> "Hello silly world"
 */
export const pinoLogger = pino({
  enabled: true,
  level,
  formatters: {
    level: formatLevel,
  },
  // Overriding Pino's "msg" key name to "message"
  errorKey: 'error',
  // Overriding Pino's "err" key name to "error"
  messageKey: 'message',
  timestamp,
})
